
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';

import {
  Annotation,
  AppliedAnnotation,
  AppliedAnnotationObserver,
  Comment,
  CommentObserver,
  CriterionResult,
  HandgradingResult,
  UltimateSubmissionPolicy,
} from 'ag-client-typescript';

import { GlobalData } from '@/app.vue';
import APIErrors from '@/components/api_errors.vue';
import Modal from '@/components/modal.vue';
import ValidatedInput from '@/components/validated_input.vue';
import CodeThemeToggle from '@/components/view_file/code_theme_toggle.vue';
import {
  handle_api_errors_async,
  handle_global_errors_async,
  make_error_handler_func,
} from '@/error_handling';
import { BeforeDestroy, Created } from '@/lifecycle';
import { deep_copy, toggle } from '@/utils';
import {
  is_integer,
  is_not_empty,
  string_to_num,
} from '@/validators';

import FilePanel from './file_panel.vue';
import { handgrading_comment_factory, HandgradingComment } from './handgrading_comment';

class ProcessingSemaphore {
  private count = 0;

  get processing() {
    return this.count !== 0;
  }

  async process<T>(body: () => Promise<T>) {
    try {
      this.count += 1;
      return await body();
    }
    finally {
      this.count -= 1;
    }
  }
}

@Component({
  components: {
    APIErrors,
    CodeThemeToggle,
    FilePanel,
    Modal,
    ValidatedInput,
  }
})
export default class Handgrading extends Vue implements AppliedAnnotationObserver,
                                                        CommentObserver,
                                                        Created,
                                                        BeforeDestroy {
  @Inject({from: 'globals'})
  globals!: GlobalData;
  d_globals = this.globals;

  @Prop({required: true, type: HandgradingResult})
  handgrading_result!: HandgradingResult;

  // When true, editing handgrading results will be disabled.
  @Prop({required: true, type: Boolean})
  readonly_handgrading_results!: boolean;

  // When true, the "prev" buttion will be disabled.
  @Prop({default: false, type: Boolean})
  is_first!: boolean;

  // When true, the "skip/next" buttion will be disabled.
  @Prop({default: false, type: Boolean})
  is_last!: boolean;

  d_handgrading_result: HandgradingResult | null = null;
  d_has_correct_submission: boolean | null = null;

  get saving() {
    return this.d_saving.processing;
  }
  d_saving = new ProcessingSemaphore();

  d_show_reset_handgrading_modal = false;
  d_resetting = false;

  d_criteria_collapsed = false;
  d_comments_collapsed = false;
  d_annotations_collapsed = true;

  d_new_comment_text = '';

  d_adjust_points_is_valid = true;

  readonly string_to_num = string_to_num;
  readonly is_integer = is_integer;
  readonly is_not_empty = is_not_empty;

  readonly UltimateSubmissionPolicy = UltimateSubmissionPolicy;

  created() {
    this.d_handgrading_result = deep_copy(this.handgrading_result, HandgradingResult);
    AppliedAnnotation.subscribe(this);
    Comment.subscribe(this);
  }

  @handle_global_errors_async
  async mounted() {
    this.d_has_correct_submission = await this.d_handgrading_result!.has_correct_submission();
  }

  beforeDestroy() {
    AppliedAnnotation.unsubscribe(this);
    Comment.unsubscribe(this);
  }

  @Watch('handgrading_result')
  on_handgrading_result_changed(
      new_result: HandgradingResult, old_result: HandgradingResult) {
    this.d_handgrading_result = deep_copy(new_result, HandgradingResult);
  }

  toggle_criterion(criterion_result: CriterionResult) {
    if (this.saving || this.readonly_handgrading_results) {
      return;
    }
    return this.d_saving.process(async () => {
      criterion_result.selected = !criterion_result.selected;
      await criterion_result.save();
      let adjustment = criterion_result.criterion.points;
      if (!criterion_result.selected) {
        adjustment *= -1;
      }
      return this.update_score(adjustment);
    });
  }

  @handle_global_errors_async
  add_comment() {
    return this.d_saving.process(async () => {
      let comment = await Comment.create(
        this.d_handgrading_result!.pk, {text: this.d_new_comment_text});
      this.d_handgrading_result!.comments.push(comment);
      this.d_new_comment_text = '';
    });
  }

  // Returns a list of AppliedAnnotations and Comments that have a location,
  // sorted by filename first, then by first line of the location.
  get handgrading_comments() {
    let comments = this.d_handgrading_result!.comments.filter(
      comment => comment.location !== null
    ).map(handgrading_comment_factory);

    let applied_annotations = this.d_handgrading_result!.applied_annotations.map(
      handgrading_comment_factory
    );

    return comments.concat(applied_annotations).sort((first, second) => first.compare(second));
  }

  get general_comments() {
    return this.d_handgrading_result!.comments.filter(comment => comment.location === null);
  }

  get can_leave_comments() {
    return !this.readonly_handgrading_results
           && (this.d_globals.user_roles.is_staff
               || this.d_handgrading_result!.handgrading_rubric.handgraders_can_leave_comments);
  }

  @handle_global_errors_async
  save_points_adjustment() {
    return this.d_saving.process(() => {
      return this.d_handgrading_result!.save_points_adjustment();
    });
  }

  @handle_global_errors_async
  save_finished_grading() {
    return this.d_saving.process(async () => {
      return this.d_handgrading_result!.save_finished_grading();
    });
  }

  get can_adjust_points() {
    return !this.readonly_handgrading_results
           && (this.d_globals.user_roles.is_staff
               || this.d_handgrading_result!.handgrading_rubric.handgraders_can_adjust_points);
  }

  @handle_global_errors_async
  delete_comment(comment: Comment | HandgradingComment) {
    if (this.saving) {
      // istanbul ignore next
      return;
    }

    return this.d_saving.process(() => {
      return comment.delete();
    });
  }

  @handle_api_errors_async(make_error_handler_func('reset_api_errors'))
  reset_handgrading() {
    return toggle(this, 'd_resetting', async () => {
        this.d_handgrading_result = await HandgradingResult.reset(this.d_handgrading_result!.group);
        this.d_has_correct_submission = true;
        this.d_show_reset_handgrading_modal = false;
    });
  }

  unchecked_total_deduction_by_annotation_pk(annotation: Annotation) {
    return this.d_handgrading_result!.applied_annotations
      .filter(result_annotation => result_annotation.annotation.pk === annotation.pk)
      .map(applied_annotation => applied_annotation.annotation.deduction)
      .reduce((a, b) => a + b, 0);
  }

  limited_deduction_by_annotation_pk(unchecked_total_deduction: number, annotation: Annotation) {
    if (annotation.max_deduction !== null && unchecked_total_deduction < annotation.max_deduction) {
      return annotation.max_deduction;
    }
    return unchecked_total_deduction;
  }

  // Must call before applying change to this.d_handgrading_result.applied_annotations
  deduction_change(annotation: Annotation, unchecked_score_change: number) {
    const unchecked_deduction_before = this.unchecked_total_deduction_by_annotation_pk(annotation);
    const actual_deduction_before = this.limited_deduction_by_annotation_pk(
      unchecked_deduction_before,
      annotation
    );
    const unchecked_deduction_after = unchecked_deduction_before + unchecked_score_change;
    const actual_deduction_after = this.limited_deduction_by_annotation_pk(
      unchecked_deduction_after,
      annotation
    );
    return actual_deduction_after - actual_deduction_before;
  }

  update_applied_annotation_created(applied_annotation: AppliedAnnotation): void {
    if (applied_annotation.handgrading_result === this.d_handgrading_result!.pk) {
      const score_change = this.deduction_change(
        applied_annotation.annotation,
        applied_annotation.annotation.deduction
      );
      this.d_handgrading_result!.applied_annotations.push(applied_annotation);
      // tslint:disable-next-line no-floating-promises
      this.update_score(score_change);
    }
  }

  update_applied_annotation_deleted(applied_annotation: AppliedAnnotation): void {
    if (applied_annotation.handgrading_result === this.d_handgrading_result!.pk) {
      const score_change = this.deduction_change(
        applied_annotation.annotation,
        -applied_annotation.annotation.deduction
      );
      this.d_handgrading_result!.applied_annotations.splice(
        this.d_handgrading_result!.applied_annotations.findIndex(
          item => item.pk === applied_annotation.pk
        ),
        1
      );
      // tslint:disable-next-line no-floating-promises
      this.update_score(score_change);
    }
  }

  update_comment_created(comment: Comment): void {
    if (comment.handgrading_result === this.d_handgrading_result!.pk
        && comment.location !== null) {
      this.d_handgrading_result!.comments.push(comment);
    }
  }

  update_comment_deleted(comment: Comment): void {
    if (comment.handgrading_result === this.d_handgrading_result!.pk) {
      this.d_handgrading_result!.comments.splice(
        this.d_handgrading_result!.comments.findIndex(
          item => item.pk === comment.pk
        ),
        1
      );
    }
  }

  update_comment_changed(comment: Comment): void {
  }

  // If d_handgrading_result.finished_grading is false,
  // adds adjustment points to d_handgrading_result.total_points.
  // Otherwise, also d_handgrading_result
  update_score(adjustment: number) {
    return this.d_saving.process(async () => {
      // If this is marked as finished grading, we want observers to be
      // notified if the score changed (so we refresh).
      if (this.d_handgrading_result!.finished_grading) {
        await this.d_handgrading_result!.refresh();
      }
      else {
        this.d_handgrading_result!.total_points += adjustment;
      }
    });
  }
}

