
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import * as ag_cli from 'ag-client-typescript';

import { GlobalData } from '@/app.vue';
import { handle_global_errors_async } from '@/error_handling';
import { SafeMap } from '@/safe_map';
import { assert_not_null, toggle } from '@/utils';

import GroupSummaryPanel from './group_summary_panel.vue';
import Handgrading from './handgrading.vue';
import { get_handgrading_status, HandgradingStatus } from './handgrading_status';

@Component({
  components: {
    GroupSummaryPanel,
    Handgrading,
  }
})
export default class HandgradingContainer extends Vue implements ag_cli.HandgradingResultObserver {
  @Prop({required: true, type: ag_cli.Course})
  course!: ag_cli.Course;

  @Prop({required: true, type: ag_cli.Project})
  project!: ag_cli.Project;

  @Prop({required: true, type: ag_cli.HandgradingRubric})
  handgrading_rubric!: ag_cli.HandgradingRubric;

  d_currently_grading: ag_cli.HandgradingResult | null = null;

  d_result_summaries: ag_cli.GroupWithHandgradingResultSummary[] = [];
  d_loading_result_summaries = true;

  d_group_sidebar_collapsed = false;
  d_grading_sidebar_collapsed = false;

  d_filters_collapsed = true;

  d_search_text = '';
  d_status_filter: HandgradingStatus | null = null;
  d_include_staff = false;

  d_staff: Set<string> = new Set<string>();

  readonly HandgradingStatus = HandgradingStatus;

  d_loading_result = false;

  @handle_global_errors_async
  async created() {
    let [staff, admins] = await Promise.all([this.course.get_staff(), this.course.get_admins()]);
    this.d_staff =  new Set([...staff, ...admins].map(user => user.username));
    await this.load_result_summaries();
    ag_cli.HandgradingResult.subscribe(this);
  }

  beforeDestroy() {
    ag_cli.HandgradingResult.unsubscribe(this);
  }

  async load_result_summaries() {
    // Because the page urls aren't correct in the dev stack,
    // we'll just keep track of the current page number.
    let page_num = 1;
    let page_size = 500;

    let page: ag_cli.HandgradingResultPage;
    do {
      page = await ag_cli.HandgradingResult.get_all_summaries_from_project(
        this.project.pk, {page_size: page_size, page_num: page_num});
      this.d_result_summaries.push(...page.results);
      page_num += 1;
    } while (page.next !== null);

    this.d_loading_result_summaries = false;
  }

  get staff_filtered_groups() {
    if (this.d_include_staff) {
      return this.d_result_summaries;
    }

    return this.d_result_summaries.filter(group => !this.d_staff.has(group.member_names[0]));
  }

  get status_filtered_groups() {
    if (this.d_status_filter ===  null) {
      return this.staff_filtered_groups;
    }

    return this.staff_filtered_groups.filter(
      group => get_handgrading_status(group) === this.d_status_filter);
  }

  get username_filtered_groups() {
    let search = this.d_search_text.trim();
    if (search === '') {
      return this.status_filtered_groups;
    }
    return this.status_filtered_groups.filter(
      group => group.member_names.some(username => username.includes(search)));
  }

  get num_finished(): number {
    return this.staff_filtered_groups.filter(
      group => get_handgrading_status(group) === HandgradingStatus.graded
    ).length;
  }

  get total_num_to_grade() {
    return this.staff_filtered_groups.filter(
      group => get_handgrading_status(group) !== HandgradingStatus.no_handgradeable_submission
    ).length;
  }

  @handle_global_errors_async
  async select_for_grading(group: ag_cli.GroupWithHandgradingResultSummary) {
    if (group.has_handgradeable_submission) {
      await toggle(this, 'd_loading_result', async () => {
        this.d_currently_grading = await ag_cli.HandgradingResult.get_or_create(group.pk);
      });
    }
  }

  get previous() {
    let index = this.index_of_currently_grading - 1;
    while (index >= 0) {
      if (this.staff_filtered_groups[index].has_handgradeable_submission) {
        return this.staff_filtered_groups[index];
      }
      index -= 1;
    }
    return null;
  }

  get next() {
    let index = this.index_of_currently_grading + 1;
    while (index < this.staff_filtered_groups.length) {
      if (this.staff_filtered_groups[index].has_handgradeable_submission) {
        return this.staff_filtered_groups[index];
      }
      index += 1;
    }
    return null;
  }

  private get index_of_currently_grading() {
    assert_not_null(this.d_currently_grading);
    return this.staff_filtered_groups.findIndex(
      group => group.pk === this.d_currently_grading!.group);
  }

  update_handgrading_result_created(handgrading_result: ag_cli.HandgradingResult): void {
    this.update_summary(handgrading_result);
  }

  update_handgrading_result_changed(handgrading_result: ag_cli.HandgradingResult): void {
    this.update_summary(handgrading_result);
  }

  update_summary(handgrading_result: ag_cli.HandgradingResult) {
    if (handgrading_result.handgrading_rubric.pk !== this.handgrading_rubric.pk) {
      return;
    }
    let to_update = this.d_result_summaries.find(
      summary => summary.pk === handgrading_result.group);

    assert_not_null(to_update);
    to_update.handgrading_result = {
      finished_grading: handgrading_result.finished_grading,
      total_points: handgrading_result.total_points,
      total_points_possible: handgrading_result.total_points_possible,
    };
  }

  update_handgrading_result_deleted(handgrading_result: ag_cli.HandgradingResult): void {
  }
}

