

import { Component, Prop, Vue } from 'vue-property-decorator';

import DOMPurify from 'dompurify';
import showdown from 'showdown';

const converter = new showdown.Converter();

@Component({})
export default class DescriptionRenderer extends Vue {
  @Prop({required: true, type: String})
  text!: string;

  get markdown_rendered() : string {
    return DOMPurify.sanitize(<string> converter.makeHtml(this.text));
  }

}
